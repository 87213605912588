import React from 'react';

import './style.css';

export default ({active}) =>{
    return active ?(
        <div className="loaderModal">
            <div className="loaderModal--spin" />
        </div>
    ):null;
};