import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import SectionTitle from '../SectionTitle';

const useStyles = makeStyles(theme => ({
  textContent:{
    '& p':{
      marginBottom: 15,
      textAlign: 'justify'
    }
  }
}));

export default function WhoWeAre(){
  const classes = useStyles();
  return(
    <Container maxWidth="lg" id="quem-somos">
      <SectionTitle text="Quem Somos" />   
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.textContent}>
          <Typography>
            A Gás Nacional é uma empresa especializada e qualificada com ampla experiência no mercado de gás natural. 
            Suas Atividades incluem: instalação. manutenção e reparos. Tem como meta, excelência nos serviços prestados.
          </Typography>

          <Typography>
            A empresa conta com uma equipe de profissionais altamente qualificados, que realizam serviços de qualidade, 
            com segurança e agilidade. Sempre atuando dentro das normas técnicas NBR ISO 9001.
          </Typography>

          <Typography>
            Trabalhamos com Engenheiro Credenciado a CEG e GEM.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.textContent}>
          <Typography>
            A Gás Nacional é uma empresa comprometida, com ampla experiência no mercado de gás natural; 
            há 15 anos voltada aos segmentos residencial e comercial no que se refira à implantação de infraestrutura 
            necessária para o abastecimento de gás natural (GN).
          </Typography>

          <Typography>            
            A missão da empresa se traduz em Trabalhar com segurança e qualidade, pois sabemos todas as normas de 
            segurança e qualidade da Ceg. Temos o dever de levar o conforto, a qualidade e a segurança que sua 
            família/empresa tanto merece.
          </Typography>

        </Grid>
      </Grid>
    </Container>
  );
}