import React, { useState } from 'react';
import Smartphone from '@material-ui/icons/Smartphone';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Drawer from '@material-ui/core/Drawer';
import Call from '@material-ui/icons/Call';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';

import logo from '../../assets/img/header/logo.png';
import wppIcon from '../../assets/img/icons/wpp.svg';

import FileToIcon from '../FileToIcon';

const useStyles = makeStyles(theme => ({  
    brand:{
      margin: '10px auto 0px auto',
      width: 140,      
    },    
    navBar:{
      backgroundColor: theme.palette.background.default,
      marginBottom: 50,
      borderRadius: 5,
      padding: '0 20px;'    
    },
    navContainer:{
      height: '100%',
    },  
    drawerButton:{
      display:'block',
      float: 'right',
      marginTop: 8,
      '& svg':{
        width: 32,
        height: 32
      }
    },
    pureLink:{
      textDecoration: 'none',
      color: 'inherit'
    },
    logoDrawer:{
      width: 140,
      margin: '20px 10px 10px 20px'
    },
    btCloseDrawer:{
      marginTop: 20,
      '& svg':{
        width: 32,
        height: 32
      }
    },
    linkRef:{
      textDecoration: 'none',
      height: '100%'
    },
    [theme.breakpoints.up('sm')]:{
      brand:{
        margin: '10px auto',
        display: 'block',
        width: 'auto',        
      },
      divider:{
        width: '90%',
        borderTop: '1px solid #efefefe0',
        borderBottom: '1px solid #c1bebee6',
        margin: '10px auto 0'
      },
      navBar:{
        height: 'auto',
        padding: '0 10px'
      },
      navLinks:{      
        padding: '0 !important',
        '& nav':{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100px'
        }
      },
      alink:{
        fontSize: '1rem',
        textDecoration: 'none',
        padding: '0 28px',
        fontWeight: 'bold',
        color: theme.palette.font.black,
        height: '100%',
        textTransform: 'uppercase'
      },
      
    },
    [theme.breakpoints.up('md')]:{
      brand:{
        margin: '0 auto',
      },
      navLinks:{
        '& nav':{
          justifyContent: 'flex-start'
        }
      }
    }
}));



export default function Menu({siteLinks}) {
  const classes =  useStyles();
  const [state, setState] = useState({
    drawer: false
  });

  const toggleDrawer = (side, open) => event =>{
    if(event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift ' )){
      return;
    }
    setState({...state, [side]: open});
  };

  const navTo = () => {    
    setState({...state, left: false});
  };

  return (
    <>
      <div className={classes.navBar}>
        <Grid container spacing={3} className={classes.navContainer}>
          <Grid item xs={8} sm={12} md={3} lg={3}>
            <img src={logo} alt="Logo Gás Nacional" className={classes.brand}/>
          </Grid>
          <Hidden only={['xs','md','lg','xl']}>
            <hr className={classes.divider}/>
          </Hidden>
          <Grid item xs={4} sm={12} md={9} lg={9} className={classes.navLinks}>
            <Hidden xsDown>
              <nav>  
                {siteLinks.map(link=>(
                  <a key={link.href} href={link.href} className={classes.linkRef}>
                    <MenuItem className={classes.alink + " " +classes.active}>                
                      {link.text}
                    </MenuItem>
                  </a>
                ))}              
              </nav>
            </Hidden>
            <Hidden smUp>
              <IconButton onClick={toggleDrawer('left', true)} className={classes.drawerButton}>
                <MenuIcon></MenuIcon>
              </IconButton>
            </Hidden>              
          </Grid>
        </Grid>
      </div>          
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        <Grid container spacing={0}>
          <Grid item xs={9}>
            <img src={logo} alt="Logo Gás Nacional" className={classes.logoDrawer}/>
          </Grid>
          <Grid item xs={3}>
            <IconButton onClick={toggleDrawer('left', false)} className={classes.btCloseDrawer}>
              <ExitToApp></ExitToApp>
            </IconButton>
          </Grid>
        </Grid>
        
        <List>          
          <Divider />
            <ListItem button component="a" onClick={(e)=>navTo()} href="#inicio">    
              <ListItemIcon><HomeIcon /></ListItemIcon>          
              <ListItemText primary='Início' />
            </ListItem>
            <ListItem button component="a" onClick={(e)=>navTo()} href="#quem-somos"> 
              <ListItemIcon><PersonIcon /></ListItemIcon>             
              <ListItemText primary='Quem somos' />
            </ListItem>
            <ListItem button component="a" onClick={(e)=>navTo()} href="#como-trabalhamos">              
              <ListItemIcon><BuildIcon /></ListItemIcon>
              <ListItemText primary='Como trabalhamos' />
            </ListItem>
            <ListItem button component="a" onClick={(e)=>navTo()} href="#servicos">               
              <ListItemIcon><Settings /></ListItemIcon>
              <ListItemText primary='Serviços' />
            </ListItem>
        </List>
          <List>          
        <Divider />
              <a href="tel:5521964599018"  className={classes.pureLink}>
                <ListItem button>
                  <ListItemIcon><Smartphone /></ListItemIcon>
                  <ListItemText primary='(21) 96459-9018' />
                </ListItem>
              </a>
              <a href="tel:552128828073"  className={classes.pureLink}>
                <ListItem button>
                  <ListItemIcon><Call /></ListItemIcon>
                  <ListItemText primary='(21) 2882-8073' />
                </ListItem>
              </a>
              <a href="mailto:gasnacionalaparelhosagas@gmail.com" className={classes.pureLink}>
                <ListItem button>
                  <ListItemIcon><MailIcon /></ListItemIcon>
                  <ListItemText 
                    style={{wordBreak: 'break-all'}}
                    primary='gasnacionalaparelhosagas@gmail.com' />
                </ListItem>
              </a>
              <a href="https://wa.me/5521964599018" className={classes.pureLink}>
                <ListItem button>
                  <ListItemIcon>
                      <FileToIcon file={wppIcon} />
                  </ListItemIcon>     
                  <ListItemText primary='Fale conosco' />
                </ListItem>
              </a>
          </List>
      </Drawer>
    </>
  );
} 