import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ourStore from '../../assets/img/others/loja.jpeg';

import SectionTitle from '../SectionTitle';

const useStyles = makeStyles(theme=>({
  container:{        
    marginBottom: 30,    
  },
  contactsWrapper:{
    marginBottom: 15,
    paddingTop: 7
  },
  contactsHeader:{
    position: 'relative'
  },
  cardTitle:{
    fontSize: '1.2rem',
    color: '#505050',
    margin: '10px auto',
    fontWeight: '700',   
  },
  phoneLink:{
    color: theme.palette.primary.main,    
    textDecoration: 'none',
    fontSize: '1.4rem',
    margin: '10px 0',    
  },
  emailLink:{
    color: theme.palette.primary.main,
    display: 'block',
    textDecoration: 'none',
    fontSize: '1.1rem',
    margin: '10px 0',
    wordBreak: 'break-all'  
  },
  wppLink:{
    color: theme.palette.primary.main,
    display: 'block',
    textDecoration: 'none',
    fontSize: '1.2rem',
    margin: '10px 0',
    wordBreak: 'break-all'  
  },
  mapLink:{
    color: theme.palette.primary.main,
    display: 'block',
    textDecoration: 'none',
    fontSize: '0.9rem',
    margin: '10px 0',
    wordBreak: 'break-all'  
  },
  myStore:{
    width: '100%'
  },
  [theme.breakpoints.up('md')]:{
  }
}));

export default function Contacts(){
  const classes = useStyles();
  return(
    <Container maxWidth="lg" id="contatos"> 
      <SectionTitle text="Nossos contatos" />              
      <Grid container spacing={2} className={classes.container}>    
        {/* Contacts */}
        <Grid item xs={12} sm={5} md={6}>
          <Typography variant="h6" className={classes.cardTitle}>
            Entre em contato
          </Typography>        
          <div>      
            <a href="tel:5521964599018"  className={classes.phoneLink}>(21) 96459-9018</a>
            <span className={classes.phoneLink}> / </span>
            <a href="tel:552128828073"  className={classes.phoneLink}>2882-8073 </a>
            <a href="mailto:gasnacionalaparelhosagas@gmail.com" className={classes.emailLink}>
              gasnacionalaparelhosagas@gmail.com               
            </a>
            <Typography variant="h6" className={classes.cardTitle}>
              WhatsApp
            </Typography>        
            <a href="https://wa.me/5521964599018" target="_blank" rel="noopener noreferrer nofollow" className={classes.wppLink}>
              Clique aqui
            </a>
            <Typography variant="h6" className={classes.cardTitle}>
              Conheça nossa loja
            </Typography>        
            <a href="https://goo.gl/maps/R2SYoGTcNAuE4uve6" target="_blank" rel="noopener noreferrer nofollow" className={classes.mapLink}>
              Av. dos Democráticos, 32 - loja A - Manguinhos, Rio de Janeiro - RJ, 21050-434
            </a>            
          </div>
        </Grid>
        
        <Grid item xs={12} sm={5} md={6}>                     
          <img src={ourStore} className={classes.myStore} alt="Nossa Loja" />                    
        </Grid>
      </Grid>
    </Container>
  );
}