import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Address from './Address';
import Menu from './Menu';
import background from '../../assets/img/header/background.jpg';
import MsgForm from './MsgForm';

const useStyles = makeStyles(theme => ({  
  backImage:{
    background: `url(${background}) no-repeat`,
    backgroundSize: 'cover',    
    paddingTop: 30,
    paddingBottom: 50,
  },
  [theme.breakpoints.up('sm')]:{
    backImage:{
      paddingTop: 0,
    },
  }
}));

export default function Header({siteLinks, setLoading, whatsapp}) {
  const classes =  useStyles();
  
  return (
    <header className={classes.backImage} id="inicio">
      <Container maxWidth="lg">
        <Address />
        <Menu siteLinks={siteLinks} />
        <MsgForm setLoading={setLoading} whatsapp={whatsapp}/>
      </Container>  
    </header>
  );
} 