import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DoneOutline from '@material-ui/icons/DoneOutline';

const useStyles = makeStyles(theme=>({
  container:{
    marginBottom: 30
  },
  whyHire_rowHeader:{    
    width: '100%',
    backgroundColor: theme.palette.thirdy.main,
    color: theme.palette.font.white, 
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: '10px 15px',
    marginBottom: 15,
    fontSize: '2rem',
    textAlign: 'center'
  },
  rowTags:{
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  whyHire_rowBenefit:{    
    backgroundColor: '#f2f2f2',
    padding: '10px 15px',
    margin: '5px 10px',
    fontSize: '1.2rem',
    display: 'flex'
  },
  iconCheck:{
    color: theme.palette.secondary.main,
    marginRight: 10,
    marginTop: 5
  },
  contactsWrapper:{
    marginBottom: 15,
    paddingTop: 7
  },
  contactsHeader:{
    position: 'relative'
  },
  
  [theme.breakpoints.up('md')]:{
  }
}));

function RowBenefit(props){
  const classes = useStyles();  
  return(
    <Typography variant="h6" className={classes.whyHire_rowBenefit}>
      <DoneOutline className={classes.iconCheck}/>
      {props.text}
    </Typography>
  );
}
export default function WhyHireUs(){
  const classes = useStyles();
  return(
    <Container maxWidth="lg"  className={classes.container}>            
      <Grid container spacing={2}>
        {/* Benefits */}
        <Typography variant="h4" className={classes.whyHire_rowHeader}>
          Porque nos contratar?
        </Typography>
        <Grid item xs={12}  className={classes.rowTags}>                    
          <RowBenefit text="Orçamento Grátis" />
          <RowBenefit text="Atendemos no Rio e Grande Rio" />
          <RowBenefit text="Trabalhamos com Engenheiro Credenciado a CEG e GEM" />
          <RowBenefit text="Teste de Estanqueidade em tubulaçao a gás c/ART" />
          <RowBenefit text="Laudo técnico p/ corpo de bombeiros" />
        </Grid>       
      </Grid>
    </Container>
  );
}