import axios from 'axios';

const API = axios.create({
    baseURL: 'https://henrisilva.com.br/my-mailer/send.php',
    headers: { 
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }
});


export const useFetch = () =>{

    const post = async (path, data) => {
        try {
            const response = await API.post(path, data);           
            if(response){
                return response.data;
            }            
        } catch (e) {
            console.log('Erro to POST data to ' + path, e);
        }
        return null;       
    }
    return {
        post
    }
}