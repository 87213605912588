import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper:{
    paddingTop: 30,
    marginBottom: '30px'
  },
  title:{
    textAlign: 'center',
    color: theme.palette.thirdy.main,
    textTransform: 'uppercase',
    margin: '60px auto 20px',
    fontWeight: 'bold',
    fontSize: '1.8rem'
  },
  divider:{
    borderTop: '1px solid #efefefe0',
    borderBottom: '1px solid #c1bebee6',
    margin: '0 auto 20px auto'
  },
  [theme.breakpoints.up('sm')]:{
    title:{
      fontSize: '2rem'
    }
  },
  [theme.breakpoints.up('md')]:{
    title:{
      fontSize: '2.3rem'
    }
  }
}));

export default function SectionTitle(props){
  const {text} = props;
  const classes = useStyles();
  return(
    <div className={classes.wrapper}>
      <Typography variant="h3" className={classes.title}>
        {text}
      </Typography>
      <hr className={classes.divider}/>
    </div>
  );
}

