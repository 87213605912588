import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFetch } from '../../hooks/useFetch';
import { Grid, Typography, Card, CardHeader, CardContent, CardActions, TextField, MenuItem, Button } from '@material-ui/core';
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme =>({
  msgTitle:{
    color: theme.palette.font.white,
    marginBottom: 20,
    fontSize: '1.8rem',
    '& span':{
      color: theme.palette.secondary.main,  
    }
  },
  msgContent:{
    color: theme.palette.font.white,
    fontSize: '1.1rem'
  },
  formHeader:{
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.font.white,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  sendButton:{
    width: '200px',
    backgroundColor: theme.palette.thirdy.main,
    color: theme.palette.font.white,
    margin: '0 auto 10px auto',
    fontSize: '1.1rem',
    fontWeight: 'bold', 
    '&:hover':{
      backgroundColor: theme.palette.thirdy.hover,
    }
  },
  [theme.breakpoints.up('sm')]:{
    msgTitle:{
      fontSize: '2rem'
    },
    msgContent:{
      fontSize: '1.6rem'
    }
  },
  [theme.breakpoints.up('md')]:{
    msgTitle:{
      fontSize: '3rem'
    },
    msgContent:{
      fontSize: '2rem'
    }
  },
}));
const services = [
  'Aplicação de Resina',
  'Nova Tubulação',
  'Vazamento',
  'Laudo Técnico',
  'Orçamento',
  'Manutenção',
  'Conversão de fogão'
];

export default function MsgForm({setLoading, whatsapp}){
  const classes =  useStyles();

  const [values, setValues] = useState({
    serviceType: '',
  });  
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  
  const sendContact = async(e) => { 
    e.preventDefault();    
    if(name !== '' && email !== ''){
      var params = new URLSearchParams();
      params.append('name', name);
      params.append('email', email);     
      params.append('service', service);     
      params.append('phone', phone);     
      params.append('message', message);  

      const text = `*Nome:* ${name}%0A*Email:* ${email}%0A*Telefone:* ${phone}%0A*Interesse:* ${service}%0A*Mensagem:* ${message}`;

      const wppLink = document.createElement('a');
      wppLink.href = `https://api.whatsapp.com/send/?phone=${whatsapp}&text=${text}`;
      wppLink.target = "_blank";
      wppLink.click();
    
      setTimeout(()=>{        
        Swal.fire(
          'Mensagem Enviada!',
          'Vamos seguir nossa conversa via WhatsApp!',
          'success'
        );

        setName('');
        setEmail('');
        setService('');
        setPhone('');
        setMessage('');
      },300);
    }
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });    
    setService(event.target.value);
  };

  return(
    <Grid container spacing={4}>
      <Grid item xs={12} md={7}>
        <Typography variant="h2" className={classes.msgTitle}>
          Utilize <span>Gás Natural</span> em sua residência ou no seu negócio
        </Typography>
        <Typography variant="body1" className={classes.msgContent}>
          Somos especializados no mercado de gás natural. Nossas atividades incluem: instalação, manutenção e reparos.
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container>
          <Grid item xs={12}>
          <form onSubmit={e=>sendContact(e)}>
            <Card>
              <CardHeader className={classes.formHeader} title="Peça já seu Orçamento!" />
              <CardContent>               
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField 
                        label="Nome"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                        value={name}
                        onChange={e=>setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField 
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField 
                        select
                        label="Serviço"
                        margin="normal"
                        variant="outlined"
                        value={values.serviceType}
                        onChange={handleChange('serviceType')}
                        fullWidth
                      >
                        {
                          services.map((ele,i)=>(
                            <MenuItem key={i} value={ele}>{ele}</MenuItem>
                          ))
                        }
                        
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField 
                        type="tel"
                        label="Telefone"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                        value={phone}
                        onChange={e=>setPhone(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField 
                        type="tel"
                        label="Recado"
                        margin="normal"
                        variant="outlined"
                        multiline
                        rowsMax="4"
                        fullWidth     
                        value={message}
                        onChange={e=>setMessage(e.target.value)}            
                      />
                    </Grid>
                  </Grid>                
              </CardContent>
              <CardActions>                
                <Button size="large" className={classes.sendButton} type="submit">
                  Enviar
                </Button>                              
              </CardActions>
            </Card>
            </form>
          </Grid>
        </Grid>
      </Grid>      
    </Grid>
  );
};