import React from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  imageIcon: {
    height: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  }
});


export default function FileToIcon(props) {
  const { file } = props;
  const classes = useStyles();
  return (
    <Icon classes={{root: classes.iconRoot}}>
      <img className={classes.imageIcon} src={file} alt=""/>
    </Icon>    
  );
}