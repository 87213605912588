import React, { useState }  from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SectionTitle from '../SectionTitle';
import { services, tags} from './servicesTags';

const useStyles = makeStyles(theme=>({
  container:{        
    marginBottom: 30,    
  },
  contactsWrapper:{
    marginBottom: 15,
    paddingTop: 7
  },
  contactsHeader:{
    position: 'relative'
  },
  cardTitle:{
    margin: '10px auto',
    fontWeight: 'bold',
    '&:hover':{
      color: theme.palette.secondary.main
    }
  },  
  tagServices:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: '1rem'
  },
  currentTag:{
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  tag:{    
    padding: '5px 7px',
    margin: '5px',    
    cursor: 'pointer'
  },
  servicesWrapper:{
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 20 
  },
  serviceCard:{    
    transition: 'all linear 0.3s',
    margin: 10,    
    height: 180,    
  },
  serviceCardImage:{
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%'
  },
  serviceCardText:{
    fontWeight: 'bold',
    fontSize: '1rem',
    paddingTop: 5
  },
  serviceShow:{        
    filter: 'grayscale(0) brightness(1)'  
  },
  serviceHide:{
    filter: 'grayscale(50) brightness(0.4)' 
  },
  [theme.breakpoints.up('sm')]:{
    servicesWrapper:{     
      gridTemplateColumns: '1fr 1fr',
    }
  },
  [theme.breakpoints.up('md')]:{
    servicesWrapper:{     
      gridTemplateColumns: '1fr 1fr 1fr',
    }
  },
  [theme.breakpoints.up('lg')]:{
    servicesWrapper:{     
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    }
  },
}));


export default function Services(){
  const classes = useStyles();
  const [currentTag, setCurrentTag] = useState('Todos');  

  const checkActive = (service, tag) => service.tags.includes(tag);
  
  return(
    <Container maxWidth="lg" id="servicos"> 
      <SectionTitle text="Nossos serviços" />              
      <Grid container spacing={2} className={classes.container}>    
        {/* Contacts */}
        <Grid item xs={12} className={classes.tagServices}>
          {tags.map(tag=>(
            <div key={tag} 
            className={[classes.tag, tag === currentTag ? classes.currentTag: ''].join(' ')}            
            onClick={()=>{
              setCurrentTag(tag);
            }}>{tag}</div>
          ))}            
        </Grid>

        <Grid item xs={12}  className={classes.servicesWrapper}>
          {services.map((service,idx)=>(        
            <div
              key={idx+service.name}
              className={[classes.serviceCard,
                checkActive(service, currentTag) ?
                classes.serviceShow : classes.serviceHide].join(' ')
              }>
              <div 
                 className={classes.serviceCardImage}
                style={{backgroundImage: `url(${service.image})`}}>                     
              </div>
              <div className={classes.serviceCardText}>{service.name}</div>
            </div>
          ))}            
        </Grid>
      </Grid>
    </Container>
  );
}