import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import Layout from './Layout';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout />
    </ThemeProvider>,
    document.querySelector('#root'),
);