import React from 'react';
import Room from '@material-ui/icons/Room';
import Smartphone from '@material-ui/icons/Smartphone';
import Call from '@material-ui/icons/Call';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({  
  addressIcon:{
    color: theme.palette.font.red,      
  }, 
  [theme.breakpoints.up('sm')]:{    
    address:{
      color: theme.palette.font.white,
      padding: '10px 0',
      marginBottom: 10
    },
    addressinfo:{
      fontSize: '100%',
      fontWeight: 'bold',
      lineHeight: '24px',
      display: 'flex',
      margin: '15px 0',
      '& svg':{
        marginRight: 5
      }
    }
  }
}));


export default function Address() {
  const classes =  useStyles();
  return (    
    <Hidden xsDown>
      <address className={classes.address}>
        <Grid container spacing={3}>
          <Grid item sm={4} md={3}>
            <Typography variant="body1" className={classes.addressinfo}>
              <Room className={classes.addressIcon}/>
              Rio de Janeiro, Brazil
            </Typography>
          </Grid>
          <Grid item sm={4} md={3}>
            <Typography variant="body1" className={classes.addressinfo}>
              <Smartphone className={classes.addressIcon}/>
              (21) 9 6459-9018
            </Typography>
          </Grid>
          <Grid item sm={4} md={3}>
            <Typography variant="body1" className={classes.addressinfo}>
              <Call className={classes.addressIcon}/>
              (21) 2882-8073
            </Typography>
          </Grid>
        </Grid>
      </address>
    </Hidden>        
  );
} 