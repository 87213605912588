import React, { useState } from 'react';
import Header from './components/Header';
import WhoWeAre from './components/WhoWeAre';
import HowWeWork from './components/HowWeWork';
import WhyHireUs from './components/WhyHireUs';
import Contacts from './components/Contacts';
import Services from './components/Services';
import Footer from './components/Footer';
import LoaderModal from './components/LoaderModal';

const siteLinks = [
  { 
    text: 'Inicio',
    href: "#inicio"
  },
  { 
    text: 'Quem somos',
    href: "#quem-somos"
  },
  { 
    text: 'Como trabalhamos',
    href: "#como-trabalhamos"
  },
  { 
    text: 'Serviços',
    href: "#servicos"
  },
  { 
    text: 'Contatos',
    href: "#contatos"
  }
];

const whatsapp = '5521964599018';
export default function Layout(){
  const [loading, setLoading] = useState(false);

  return(
    <>      
      <Header siteLinks={siteLinks} setLoading={setLoading} whatsapp={whatsapp}/>
      <WhoWeAre/>
      <HowWeWork/>
      <WhyHireUs/>
      <Services />
      <Contacts/>
      <Footer siteLinks={siteLinks} whatsapp={whatsapp}/>
      <LoaderModal active={loading} />
    </>
  );
}