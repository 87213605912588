import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import SectionTitle from '../SectionTitle';
import visit from '../../assets/img/icons/icone-visita-e-orcamento.png';
import project from '../../assets/img/icons/icone-projeto-e-execucao.png';
import conclusion from '../../assets/img/icons/icone-conclusao-e-avaliacao.png';

const useStyles = makeStyles(theme=>({
  sectionWrapper:{
    marginBottom: 40
  },
  cardWrapper:{
    textAlign: 'center'
  },
  cardTitle:{
    margin: '10px auto',
    fontWeight: 'bold',
    '&:hover':{
      color: theme.palette.secondary.main
    }
  }
}));

function CardHowWork(props){
  const {img, title, subtitle} = props;
  const classes = useStyles();
  return(
    <div className={classes.cardWrapper}>
      <img src={img} alt={title} className={classes.cardImg}/>
      <Typography variant="h5" className={classes.cardTitle}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.cardSubTitle}>
        {subtitle}
      </Typography>
    </div>
  );
}

export default function HowWeWork(){
  const classes = useStyles();
  return(
    <Container maxWidth="lg" id="como-trabalhamos" className={classes.sectionWrapper}>
      <SectionTitle text="Como trabalhamos" />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4}>
          <CardHowWork 
            img={visit} 
            title="Visita e Orçamento"
            subtitle="Entre em contato para solicitar um serviço, iremos agendar uma visita técnica para uma avaliação."
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardHowWork 
            img={project} 
            title="Projeto e Execução"
            subtitle="Após a avaliação do serviço solicitado, o técnico comparecerá para executar o trabalho em data pré agendada."
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardHowWork 
            img={conclusion} 
            title="Conclusão e Avaliação"
            subtitle="Avalie o serviço prestado para definir se o projeto foi bem executado para maior segurança ao cliente."
          />
        </Grid>
      </Grid>
    </Container>
  );
}