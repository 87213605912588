import fogoesUrl from '../../assets/img/services/fogao.png';
import aquecedoresUrl from '../../assets/img/services/aquecedor.png';
import boilersUrl from '../../assets/img/services/boilers.png';
import coifasUrl from '../../assets/img/services/coifa.jpg';
import testesUrl from '../../assets/img/services/teste-estanqueidade.jpg';
import conversaoUrl from '../../assets/img/services/conversao-fogoes.png';
import aplicaresinaUrl from '../../assets/img/services/aplicaresina.jpg';
import tubulacaoUrl from '../../assets/img/services/tubulacao_gas.jpg';


const tags = [
    'Todos','Instalações', 'Manutenções', 'Limpeza', 'Conversão',
    'Resina', 'Tubulações', 'Vistoria',
  ];
  
  const services = [
    {
      tags: ['Todos','Instalações','Manutenções', 'Conversão', 'Limpeza'],
      name: 'Fogões',
      image: fogoesUrl
    },
    {
      tags: ['Todos','Instalações','Manutenções','Conversão'],
      name: 'Conversão de fogões',
      image: conversaoUrl
    }, 
    {
      tags: ['Todos','Instalações','Manutenções'],
      name: 'Boilers',
      image: boilersUrl
    },
    {
      tags: ['Todos','Instalações','Manutenções'],
      name: 'Aquecedores',
      image: aquecedoresUrl
    },
    {
      tags: ['Todos','Instalações','Manutenções', 'Limpeza'],
      name: 'Coifas',
      image: coifasUrl
    },  
    {
      tags: ['Todos','Instalações','Manutenções','Tubulações','Resina'],
      name: 'Tubulações',
      image: tubulacaoUrl
    },  
    
    {
      tags: ['Todos','Manutenções','Tubulações','Resina'],
      name: 'Aplicação de Resina',
      image: aplicaresinaUrl
    },  
    {
      tags: ['Todos','Vistoria'],
      name: 'Teste de Estanqueidade',
      image: testesUrl
    }
  
  ];
  
export { services, tags};