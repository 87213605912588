import React from 'react'
import { Container, Grid } from '@material-ui/core';
import Icons from "../Icons";

import "./style.css";

const Footer = ({siteLinks, whatsapp}) =>{

    return(
        <footer className="siteFooter">
            <Container>
                <Grid container>
                    <Grid item xs={12} className="siteFooter__contacts">
                        <a href={`https://api.whatsapp.com/send?phone=${whatsapp}`} rel="noopener noreferrer" target="_blank">
                            <Icons.whatsapp className="siteFooter__contacts--whatsappIcon"/>
                        </a>
                        <div>Gás Nacional</div>
                    </Grid>
                    <Grid item xs={12} className="siteFooter__links">
                        {siteLinks.map((link,index)=>(
                            <a key={`footerLink${index}`} href={`${link.href}`} >{link.text}</a>
                        ))}
                    </Grid>
                    <Grid item xs={12} className="siteFooter__copyright">©Henri Silva, copyright. 2020.</Grid>
                </Grid>
            </Container>
        </footer>
    );
}

export default Footer;